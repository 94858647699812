<template>
  <div class="custom">
    <div class="search_wrap">
      <div class="search_box">
        <el-input class="ipt" placeholder="客户名称" prefix-icon="iconfont iconfangdajing" v-model="state.queryParams.customerName" @input="handleChange">
        </el-input>
        <div class="select_wrap">
          <div class="select_title">类型</div>
          <el-select class="my_select" v-model="state.queryParams.customerType" placeholder="请选择" size="medium"
            clearable @change="handleChange">
            <el-option v-for="item in state.customerTypeArr" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </div>
        <div class="select_wrap">
          <div class="select_title">标签</div>
          <el-select class="my_select" v-model="state.queryParams.tagIdList" placeholder="请选择" size="medium" clearable multiple @change="handleChange">
            <el-option v-for="item in state.customerTagArr" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </div>
      </div>
      <el-button type="primary" @click="handleAddCustom">新建客户</el-button>
    </div>
    <div class="list_wrap" v-loading="loading">
      <div class="list_item" @click="goDetail(item.customerId)" v-for="(item, index) in state.customList" :key="index">
        <div class="item_cell">
          <div class="cell_info">
            <div class="date">{{item.birthday}}</div>
            <div class="type">{{item.customerTypeName}}</div>
          </div>
          <div class="name">{{item.customerName}}</div>
        </div>
        <div class="item_cell">{{item.tagName}}</div>
        <div class="item_cell">案件<span class="num">{{item.caseCount?item.caseCount : 0}}</span></div>
        <div class="item_cell">项目<span class="num">{{item.projectCount?item.projectCount:0}}</span></div>
        <div class="item_cell">{{item.customerRemark}}</div>
      </div>
    </div>
    <div class="pagination_wrap">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1"
        :page-sizes="PAGE_SIZES" :page-size="PAGE_SIZE" layout="total, sizes, prev, pager, next, jumper"
        :total="state.total">
      </el-pagination>
    </div>
    <!-- 新建客户弹框 -->
    <MyDialog title="填写客户基本信息" :isShow="isShow" :closeDialog="handleCloseDialog">
      <div class="custom_form">
        <el-form :model="state.customForm" :rules="state.rules" ref="customForm" label-width="100px"
          class="demo-ruleForm">
          <div class="form_box">
            <el-form-item label="客户名称" class="form_item_type" prop="customerType">
              <el-select v-model="state.customForm.customerType" size="medium">
                <el-option v-for="item in state.customerTypeArr" :key="item.key" :label="item.value" :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerName" class="form_item_name">
              <el-input v-model="state.customForm.customerName" size="medium"></el-input>
            </el-form-item>
          </div>

          <el-form-item label="客户标签">
            <el-select v-model="state.customForm.tagList" multiple style="width: 100%;" placeholder="请选择" size="medium">
              <el-option v-for="item in state.customerTagArr" :key="item.key" :label="item.value" :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="生日">
            <el-date-picker v-model="state.customForm.birthday" type="date" style="width: 100%" placeholder="年/月/日"
              size="medium" @change="handleChangeTime">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="state.customForm.customerRemark" size="medium"></el-input>
          </el-form-item>
          <div class="btn_wrap">
            <el-button class="btn" type="primary" @click="save">保存</el-button>
          </div>
        </el-form>
      </div>
    </MyDialog>
  </div>
</template>

<script>
  import MyDialog from '@/components/myDialog'
  import {
    ElMessage
  } from 'element-plus'
  import {
    defineComponent,
    reactive,
    ref,
    onMounted
  } from 'vue';
  import {
    useRouter
  } from 'vue-router'
  import {
    useConst
  } from '@/hooks/usePagination'
  import {
    getListByDictType
  } from '@/api'
  import {
    getCustomerList,
    addCustomer
  } from '@/api/custom'
  export default defineComponent({
    name: "custom",
    components: {
      MyDialog
    },
    setup() {
      let loading = ref(false)
      let isShow = ref(false)
      let customForm = ref(null)
      let state = reactive({
        queryParams: {
          customerType: null,
          tagIdList: [],
          customerName: null,
          pageNo: 1,
          pageSize: 10
        },
        customForm: {
          customerName: '',
          customerType: 1,
          birthday: '',
          customerRemark: '',
          tagList: []
        },
        customerTagArr: [],
        customerTypeArr: [],
        rules: {
          customerType: [
            {
              required: true,
              message: '请选择客户类型',
              trigger: 'blur'
            }
          ],
          customerName: [{
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }]
        },
        customList: [],
        total: 0
      })
      const router = useRouter()
      const handleChange = (val) => {
        getList()
      }
      const SizeChange = (val) => {
        // console.log(val);
        state.queryParams.pageSize = val
        getList()
      }
      const CurrentChange = (val) => {
        // console.log(val);
        state.queryParams.pageNo = val
        getList()
      }
      const save = () => {
        customForm.value.validate(async (valid) => {
          if (valid) {
            let res = await addCustomer(state.customForm)
            if (res.code == 200) {
              ElMessage.success("保存成功")
              isShow.value = false
              state.queryParams.pageNo = 1
              getList()
            }
          }
        })
      }
      // 获取客户类型
      const getCustomerType = async () => {
        let res = await getListByDictType({
          id: 10005
        })
        state.customerTypeArr = res.data
      }
      // 获取客户标签
      const getCustomTag = async () => {
        let res = await getListByDictType({id: 10031})
        state.customerTagArr = res.data
      }
      // 获取客户列表
      const getList = async () => {
        loading.value = true
        let res = await getCustomerList(state.queryParams)
        loading.value = false
        // console.log('page', res);
        if(res.code == 200){
          state.customList = res.data.records
          state.total = parseInt(res.data.total)
        }
      }
      // 格式化时间
      const formatTime = (dataStr) => {
        let date = new Date(dataStr);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return y + '-' + m + '-' + d
      }
      const handleChangeTime = (val) => {
        // let time = formatTime(val)
        // console.log(time);
        state.customForm.birthday = formatTime(val)
      }
      onMounted(() => {
        getCustomerType()
        getList()
        getCustomTag()
      })
      const {
        PAGE_SIZE,
        PAGE_SIZES,
        handleSizeChange,
        handleCurrentChange
      } = useConst(SizeChange, CurrentChange)
      const handleCloseDialog = () => {
        isShow.value = false
      }
      const handleAddCustom = () => {
        isShow.value = true
        state.customForm = {
          customerName: '',
          customerType: 1,
          birthday: '',
          customerRemark: '',
          tagList: []
        }
      }
      const goDetail = (customerId) => {
        router.push({
          path: '/customDetail',
          query: {
            customerId
          }
        })
      }
      return {
        state,
        goDetail,
        SizeChange,
        CurrentChange,
        PAGE_SIZE,
        PAGE_SIZES,
        handleSizeChange,
        handleCurrentChange,
        handleCloseDialog,
        isShow,
        handleAddCustom,
        customForm,
        save,
        handleChangeTime,
        handleChange,
        loading,
        getList
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '@/assets/style/comman.scss';

  .custom {
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    border-radius: 20px;
    padding-bottom: 30px;

    .search_wrap {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      border-bottom: 1px solid #edeff2;

      .search_box {
        flex: 1;
        display: flex;

        .ipt {
          width: 213px;

          ::v-deep .el-input__inner {
            border-radius: 20px;
          }
        }

        .select_wrap {
          height: 40px;
          border-radius: 20px;
          border: 1px solid #edeff2;
          margin-left: 10px;
          display: flex;
          align-items: center;

          .select_title {
            border-right: 1px solid #edeff2;
            height: 14px;
            width: 44px;
            text-align: center;
            line-height: 14px;
            font-size: 14px;
            color: #606266;
          }

          .my_select {
            flex: 1;

            ::v-deep .el-input__inner {
              border-radius: 0 20px 20px 0;
              border: none;
            }
          }
        }
      }
    }

    .list_wrap {
      padding: 0 20px;

      .list_item {
        height: 100px;
        display: flex;
        border-bottom: 1px solid #edeff2;
        align-items: center;
        justify-content: center;

        .item_cell {
          flex: 1;
          display: flex;
          // flex-direction: column;
          flex-wrap: wrap;

          .cell_info {
            display: flex;
            color: #606266;
            font-size: 12px;
            width: 100%;

            .type {
              margin-left: 40px;
            }
          }

          .name {
            font-size: 14px;
            color: #333;
            margin-top: 15px;
          }

          .num {
            color: $main-color;
            margin-left: 15px;
          }
        }

        &:hover {
          background: #fafafa;
        }
      }
    }

    .pagination_wrap {
      padding: 0 20px;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }

  .custom_form {
    padding: 20px 30px;

    .form_box {
      display: flex;

      .form_item_type {
        flex: 0 0 260px;
      }

      .form_item_name {
        flex: 1;

        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }

    .btn_wrap {
      display: flex;
      justify-content: center;
    }
  }
</style>